import React from 'react';
import {
  createStyles,
  Menu,
  Center,
  Header,
  Container,
  Group,
  Button,
  Burger,
  rem,
  Stack,
  Flex,
  Text
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconArrowRight } from '@tabler/icons-react';
import PoppinLogo from '../assets/images/poppin.png'
import { getDatabase, push, ref } from 'firebase/database'

const HEADER_HEIGHT = 100 + 40

const useStyles = createStyles((theme) => ({
  inner: {
    height: 100,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: "80rem"
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
  header: {
    backgroundColor: theme.colors.dark[9]
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  linkLabel: {
    marginRight: rem(5),
  },
}));


export function Navbar({ links, btnRef }) {
  const { classes } = useStyles();
  const [opened, { toggle }] = useDisclosure(false)
  const db = getDatabase()  


  const items = links.map((link) => {
    const menuItems = link.links?.map((item) => (
      <Menu.Item key={item.link}>{item.label}</Menu.Item>
    ));

    if (menuItems) {
      return (
        <Menu key={link.label} trigger="hover" transitionProps={{ exitDuration: 0 }} withinPortal>
          <Menu.Target>
            <a
              href={'#'}
              className={classes.link}
              onClick={(event) => {
                event.preventDefault()
              }}
            >
              <Center>
                <span className={classes.linkLabel}>{link.label}</span>
                <IconChevronDown size={rem(12)} stroke={1.5} />
              </Center>
            </a>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <a
        key={link.label}
        href={'#'}
        className={classes.link}
        onClick={(event) => {event.preventDefault(); link.scrollToRef.current?.scrollIntoView({ behavior: 'smooth' });
      
        const poppinRef = ref(db, 'poppin')
        push(poppinRef, {
          'navbarBtn': link.label
        })
      
      
      }}
      >
        {link.label}
      </a>
    );
  });

  return (
    <Header height={HEADER_HEIGHT} sx={{ width: "100%", zIndex: 0 }}>
      <Flex sx={{width: "100%", backgroundColor :"black", minHeight: 40, alignItems: 'center', justifyContent: "center"}}>
        <Flex sx={{    maxWidth: "80rem"}}>
          <Text sx={{ textAlign: "center", marginTop: 0, maxWidth: "90vw", color: "white", fontSize: 14}}>👋 Schedule a demo and ask about our bulk pricing discounts</Text>

        </Flex>
      </Flex>
      <Container className={classes.inner}>
      
        <Stack sx={{width: 150, alignItems: 'flex-start'}}>
          <img width={59} src={PoppinLogo} />

        </Stack>
        <Group spacing={5} className={classes.links}>
          {items}
        </Group>
        <Button variant='gradient' gradient={{ from: '#ff606d', to: '#ffbc71', deg: 90 }} onClick={() => {
        
              btnRef.current?.scrollIntoView({ behavior: 'smooth' })
              const poppinRef = ref(db, 'poppin')
              push(poppinRef, {
                'btnId': 1
              })

        }}  radius="xl" h={45} w={150}>
          Get Started
        </Button>
      </Container>
    </Header>
  );
}