import React, { useEffect, useState } from "react";
import Home from './components/Home'
import { MantineProvider } from "@mantine/core";
import { initializeApp } from "firebase/app"
import { Notifications } from '@mantine/notifications';

const firebaseConfig = {
  apiKey: "AIzaSyATIvpQZhxiKU2CLuR7QzEmyDDMRYhIIiI",
  authDomain: "poppinhr.firebaseapp.com",
  databaseURL: "https://poppinhr-default-rtdb.firebaseio.com",
  projectId: "poppinhr",
  storageBucket: "poppinhr.appspot.com",
  messagingSenderId: "802634337771",
  appId: "1:802634337771:web:a60f127bd7fde953811dae"
};

const app = initializeApp(firebaseConfig)

export default function App() {

  return (
    
    <div className="App">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
        <MantineProvider  withNormalizeCSS theme={{ colorScheme: 'light', primaryColor: "orange" }} >
        <Notifications position="top-center"/>

          <Home/>

        </MantineProvider>
    </div>
  );
}
