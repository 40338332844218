import React, {useState, useRef} from 'react';
import { createStyles, Container, Text, Button, Center, rem, Flex, Input, Stack, Title, Badge, Card, Grid, Divider, Group } from '@mantine/core'
import { IconArrowRight, IconMail, IconTrendingUp, IconBrandJavascript, IconBrandReact, IconBrandAws, IconBrandTypescript, IconBrandMysql, IconBrandCSharp, IconBrandDocker, IconUser, IconBuilding, IconBrandGithub, IconLink, IconUsers, IconCircleCheckFilled } from '@tabler/icons-react'
import { getDatabase, push, ref } from 'firebase/database'
import { notifications } from '@mantine/notifications'
import { Navbar } from './Navbar'
import { CustomFooter } from './Footer';
import {FaAws, FaJava, FaReact, FaPython, FaDocker } from 'react-icons/fa'
import { BiLogoSlack, BiLogoTypescript, BiLogoPostgresql } from 'react-icons/bi'
import { useInputState } from '@mantine/hooks'
import uber from '../assets/images/uber.svg'
import amazon from '../assets/images/amazon.svg'
import airbnb from '../assets/images/airbnb.svg'
import asos from '../assets/images/asos.svg'
import doordash from '../assets/images/doordash.svg'
import target from '../assets/images/target.svg'
import walmart from '../assets/images/walmart.svg'
import { BsCheckCircleFill, BsCreditCardFill } from 'react-icons/bs'
import { IoMdOptions } from 'react-icons/io'
import { ImLoop } from 'react-icons/im'
import { FaPeopleCarry } from 'react-icons/fa'
import { MdLeaderboard } from 'react-icons/md'



const useStyles = createStyles((theme) => ({
  innerContainer: {
    width: "100%",
    flex: 1,
    flexDirection: "column",
    maxWidth: '80rem',
    alignItems: 'center'
  },
  header: {
    color: "black", 
    fontSize: 75, 
    lineHeight: 1.1, 
    marginBottom: 0, 
    paddingBottom: 0,
    [theme.fn.smallerThan('md')]: {
      fontSize: 60
    },
    maxWidth: "90vw"
  },
  outerContainer: {
    backgroundColor: "#f3f7fd",
    width: "100%",
    flexDirection: "column",
    alignItems: 'center',
  },
  sectionOne: {
    alignItems: "center",
    height: "80vh",
    justifyContent: "center"
  }
}));

export default function HeroTitle() {
  const { classes } = useStyles();
  const [email, setEmail] = useInputState('')
  const [company, setCompany] = useInputState('')
  const [name, setName] = useInputState('')

  const homeScrollToRef = useRef(null)
  const featuresScrollToRef = useRef(null)
  const pricingScrollToRef = useRef(null)
  const rewardsScrollToRef = useRef(null)
  const demoScrollToRef = useRef(null)

  const db = getDatabase()  
  
  const submit = async () => {
    if (!email || !company || !name) {
      notifications.show({
        color: 'red',
        title: "Error",
        message: 'Please fill out all fields.'
      }) 
      return
    }
    const incipientRef = ref(db, 'poppin')
    await push(incipientRef, {
      email,
      company,
      name,
    })
    setEmail("")
    setCompany("")
    setName("")
    notifications.show({
      color: 'green',
      title: "Success",
      message: 'Our team will be in touch shortly.'
    })
    
  }

  const scrollToDemo = (btnId) => {
    demoScrollToRef.current?.scrollIntoView({ behavior: 'smooth' })
    const poppinRef = ref(db, 'poppin')
    push(poppinRef, {
      btnId
    })
  }

  return (
    <Flex className={classes.outerContainer}>
      <Navbar btnRef={demoScrollToRef} links={[{label: "Home",  scrollToRef: homeScrollToRef}, {label: "Features",  scrollToRef: featuresScrollToRef}, {label: "Rewards", scrollToRef: rewardsScrollToRef}, {label: "Pricing", link: "/pricing", scrollToRef: pricingScrollToRef}]}/>
      <Flex className={classes.innerContainer}>
        <Stack ref={homeScrollToRef} className={classes.sectionOne}>
          <Title weight={800} align='center' mb={0} className={classes.header} order={1}>
            Build a <Text inline component='span' variant="gradient" gradient={{ from: '#ff606d', to: '#ffbc71', deg: 90 }}>poppin'</Text> <br/> company culture
          </Title>
          <Flex sx={{maxWidth: 550}}>
          <Text sx={{ textAlign: "center", marginTop: 0, maxWidth: '90vw'}} color='dimmed' weight={500}>Our peer-to-peer recognition program cultivates an engaging workplace culture that enhances employee retention 🎉</Text>

          </Flex>
          <Group>
            <Button onClick={() => scrollToDemo(2)} rightIcon={<IconArrowRight/>} mt={15}  color='dark' radius="xl" h={45} >
              Book a Demo
            </Button>
          </Group>
        </Stack>
        <Stack ref={featuresScrollToRef} sx={{paddingTop: 100, paddingBottom: 150, alignItems: 'center' }}>
          <Grid sx={{maxWidth: "90vw"}}>
            <Grid.Col span={12}>
              <Badge>Our Features</Badge>
            </Grid.Col>
            <Grid.Col lg={4} md={12}>
              <Card withBorder sx={{width: "100%"}}>
                <Badge h={50} sx={{marginBottom: 10}}>
                  <Center>
                    <BiLogoSlack size={25}/>
                  </Center>
                </Badge>
                <Title order={3}>Slack Integration</Title>
                <Text sx={{marginTop: 10}} color='dimmed'>
                  Install our Slack app within your workspace to enable seamless interaction for your employees.
                </Text>
              </Card>
            </Grid.Col>
            <Grid.Col lg={4} md={12}>
              <Card withBorder sx={{width: "100%"}}>
                <Badge h={50} sx={{marginBottom: 10}}>
                  <Center>
                    <IoMdOptions size={22}/>
                  </Center>
                </Badge>
                <Title order={3}>Points Allocation</Title>
                <Text sx={{marginTop: 10}} color='dimmed'>
                  Allocate points that your employees can utilize for rewarding each other.</Text>
              </Card>
            </Grid.Col>
            <Grid.Col lg={4} md={12}>
              <Card withBorder sx={{width: "100%"}}>
                <Badge h={50} sx={{marginBottom: 10}}>
                  <Center>
                    <FaPeopleCarry size={25}/>
                  </Center>
                </Badge>
                <Title order={3}>Peer to Peer Recognition</Title>
                <Text sx={{marginTop: 10}} color='dimmed'>
                Empower your employees to foster a positive work environment by celebrating one another via points.
                </Text>
              </Card>
            </Grid.Col>
            <Grid.Col lg={4} md={12}>
              <Card withBorder sx={{width: "100%"}}>
                <Badge h={50} sx={{marginBottom: 10}}>
                  <Center>
                    <ImLoop size={20}/>
                  </Center>
                </Badge>
                <Title order={3}>Recognition Automation</Title>
                <Text sx={{marginTop: 10}} color='dimmed'>
                  Establish recurring points for work anniversaries, birthdays, and more.
                </Text>
              </Card>
            </Grid.Col>
            <Grid.Col lg={4} md={12}>
              <Card withBorder sx={{width: "100%"}}>
                <Badge h={50} sx={{marginBottom: 10}}>
                  <Center>
                    <MdLeaderboard size={25}/>
                  </Center>
                </Badge>
                <Title order={3}>Leaderboard Tracking</Title>
                <Text sx={{marginTop: 10}} color='dimmed'>
                  Inspire employees to strive for the leaderboard's pinnacle through acknowledgment.
                </Text>
              </Card>
            </Grid.Col>
            <Grid.Col lg={4} md={12}>
              <Card withBorder sx={{width: "100%"}}>
                <Badge h={50} sx={{marginBottom: 10}}>
                  <Center>
                    <BsCreditCardFill size={22}/>
                  </Center>
                </Badge>
                <Title order={3}>Gift Card Exchange</Title>
                <Text sx={{marginTop: 10}} color='dimmed'>
                  Enable the conversion of points into gift cards from a diverse range of brands.
                </Text>
              </Card>
            </Grid.Col>
          </Grid>
        </Stack>
      </Flex>
        <Stack id='abc' ref={rewardsScrollToRef}  sx={{paddingTop: 100, paddingBottom: 150, alignItems: 'center', backgroundColor: 'black', width: "100%" }}>
          <Flex className={classes.innerContainer}>
            <Grid gutter={50} sx={{maxWidth: "90vw"}}>
              <Grid.Col span={12}>
                <Center sx={{marginBottom: 30}}>
                  <Title align='center' weight={400} color='white'>Gift Cards From Leading Global Brands Including:</Title> 

                </Center>
                
              </Grid.Col>
              <Grid.Col lg={4} md={12}>
                <Center>
                  <img src={amazon} />
                </Center>
              </Grid.Col>
              <Grid.Col lg={4} md={12}>
                <Center>
                  <img src={uber} />
                </Center>
              </Grid.Col>
              <Grid.Col lg={4} md={12}>
                <Center>
                  <img src={target} />
                </Center>
              </Grid.Col>
              <Grid.Col lg={4} md={12}>
                <Center>
                  <img src={airbnb} />
                </Center>
              </Grid.Col>
              <Grid.Col lg={4} md={12}>
                <Center>
                  <img src={walmart} />
                </Center>
              </Grid.Col>
              <Grid.Col lg={4} md={12}>
                <Center>
                  <img src={doordash} />
                </Center>
              </Grid.Col>
            </Grid>
          </Flex>
        </Stack>







        
        <Stack ref={pricingScrollToRef} sx={{paddingBottom: 100, paddingTop: 100, alignItems: 'center', width: "100%" }}>
          <Grid sx={{maxWidth: "90vw"}}>
              <Grid.Col span={12}>
                <Title order={1}>Pricing</Title>
              </Grid.Col>
              <Grid.Col lg={6} sm={12}>
                <Card withBorder sx={{width: "100%", minWidth: 300}}>
                  <Stack spacing={0} align='center'>
                    <Title order={3}>Standard</Title>
                    <Title size={45}>$2.00</Title>
                    <Text size={14} color='dimmed' align='center'>per user, billed monthly</Text>
                    <Text size={14} color='dimmed' align='center'>plus cost of rewards</Text>

                    <Button onClick={() => scrollToDemo(3)} rightIcon={<IconArrowRight/>} mt={15} variant='gradient' gradient={{ from: '#ff606d', to: '#ffbc71', deg: 90 }}  radius="md" h={40} w={150}>
                      Book Demo
                    </Button>
                    <Group mt={25} sx={{width: "100%"}}>
                      <BsCheckCircleFill color='#ffbc71'  />
                      <Text color='dimmed' size={14} align='center'>Engaging peer-to-peer recognition</Text>
                    </Group>
                    <Group mt={25} sx={{width: "100%"}}>
                      <BsCheckCircleFill color='#ffbc71'  />
                      <Text color='dimmed' size={14} align='center'>Gift card payouts</Text>
                    </Group>
                    <Group mt={25} sx={{width: "100%"}}>
                      <BsCheckCircleFill color='#ffbc71'  />
                      <Text color='dimmed' size={14} align='center'>Slack integration</Text>
                    </Group>
                    <Group mt={25} sx={{width: "100%"}}>
                      <BsCheckCircleFill color='#ffbc71'  />
                      <Text color='dimmed' size={14} align='center'>Leaderboard tracking</Text>
                    </Group>
                    <Group mt={25} sx={{width: "100%"}}>
                      <BsCheckCircleFill color='#ffbc71'  />
                      <Text color='dimmed' size={14} align='center'>24/7 customer support</Text>
                    </Group>
                  </Stack>
                 
                </Card>
              </Grid.Col>
              <Grid.Col lg={6} sm={12}>
                <Card withBorder sx={{width: "100%", height: "100%"}} >
                  <Stack spacing={0} align='center'>
                    <Title order={3}>Pro</Title>
                    <Title size={45}>$3.00</Title>
                    <Text size={14} color='dimmed' align='center'>per user, billed monthly</Text>
                    <Text size={14} color='dimmed' align='center'>plus cost of rewards</Text>

                    <Button onClick={() => scrollToDemo(4)} rightIcon={<IconArrowRight/>} mt={15} variant='gradient' gradient={{ from: '#ff606d', to: '#ffbc71', deg: 90 }}  radius="md" h={40} w={150}>
                      Book Demo
                    </Button>
                    <Group mt={25} sx={{width: "100%"}}>
                      <BsCheckCircleFill color='#ffbc71'  />
                      <Text color='dimmed' size={14} align='center'>Company branding</Text>
                    </Group>
                    <Group mt={25} sx={{width: "100%"}}>
                      <BsCheckCircleFill color='#ffbc71'  />
                      <Text color='dimmed' size={14} align='center'>Birthdays & anniversaries automation</Text>
                    </Group>
                    <Group mt={25} sx={{width: "100%"}}>
                      <BsCheckCircleFill color='#ffbc71'  />
                      <Text color='dimmed' size={14} align='center'>Brandable swag rewards</Text>
                    </Group>
                  </Stack>

                </Card>
              </Grid.Col>
            </Grid>
      
        </Stack>
        <Stack ref={demoScrollToRef} sx={{paddingBottom: 140, alignItems: 'center', width: "100%",}}>
          <Stack spacing={0} sx={{width: 400, maxWidth: "90vw", marginTop: 100}}>
            <Title order={2}>Schedule a Demo</Title>
            <Text color='dimmed' >Enter your info below, and our team will reach out.</Text>

          <Card withBorder shadow='sm' sx={{marginTop: 20}}>
            
            <Input.Wrapper label='Name'>
              <Input value={name} onChange={setName} placeholder='John Doe' icon={<IconUser size={'1.1rem'}/>}>

              </Input>
            </Input.Wrapper>
            <Input.Wrapper sx={{marginTop: 10}} label='Email'>
              <Input value={email} onChange={setEmail} placeholder='name@example.com' icon={<IconMail size={'1.1rem'}/>}>

              </Input>
            </Input.Wrapper>
            <Input.Wrapper sx={{marginTop: 10}} label='Company'>
              <Input value={company} onChange={setCompany} placeholder='Acme Inc.' icon={<IconBuilding size={'1.1rem'}/>}>

              </Input>
            </Input.Wrapper>

            <Button mt={45} onClick={submit} rightIcon={<IconArrowRight/>}  variant='gradient' gradient={{ from: '#ff606d', to: '#ffbc71', deg: 90 }}  radius="md" h={40} w={150}>
              Submit
            </Button>
            
          </Card>
          </Stack>


        </Stack>
       
      <CustomFooter data={[]}/>
    </Flex>
  )
}